.section-introduce {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .content {
    > * + .box {
      margin-top: 3.2813vw;

      @include mobile {
        margin-top: 23px;
      }
    }

    .box:first-of-type {
      .bg-text {
        margin-top: 2.0833vw;

        @include mobile {
          margin-top: 20px;
        }
      }
    }

    .box:nth-of-type(2) {
      .bg-text {
        margin-bottom: 4.1146vw;
        position: relative;

        @include mobile {
          margin-bottom: 36px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -2.0573vw;
          left: 50%;
          transform: translate(-50%, 50%);
          width: 2.0833vw;
          height: 1.8229vw;
          background-color: $red;
          clip-path: polygon(50% 100%, 0 0, 100% 0);

          @include mobile {
            bottom: -18px;
            width: 20px;
            height: 17px;
          }
        }
      }
    }

    .box:nth-of-type(3) {
      .image-list {
        margin-top: 0.7813vw;

        @include mobile {
          margin-top: 8px;
        }
      }
    }

    > .sentence:first-of-type {
      margin-top: 3.2813vw;

      @include mobile {
        margin-top: 23px;
      }
    }

    .box:nth-of-type(4),
    .box:nth-of-type(5),
    .box:nth-of-type(6) {
      img {
        margin-top: 1.8229vw;

        @include mobile {
          margin-top: 18px;
        }
      }
    }
    .box:nth-of-type(7),
    .box:nth-of-type(8) {
      .bg-text {
        margin-top: 2.0833vw;

        @include mobile {
          margin-top: 18px;
        }
      }
      img {
        margin-top: 2.4479vw;

        @include mobile {
          margin-top: 23px;
        }
      }
    }

    .link-list {
      margin-top: 6.6146vw;

      @include mobile {
        margin-top: 47px;
      }
    }
  }

  .product-list {
    overflow: hidden;

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: -2.0834vw -2.4479vw -2.4479vw;

      @include mobile {
        display: block;
        margin: 0;
      }
    }

    &__item {
      padding: 2.0834vw 2.4479vw 2.4479vw;
      border: 1px solid $red;

      @include mobile {
        padding: 0;
        border: none;
      }

      + .product-list__item {
        @include mobile {
          margin-top: 10px;
        }
      }
      &:nth-of-type(1) .product__text,
      &:nth-of-type(2) .product__text {
        min-height: 8.2853vw;
        @include mobile {
          min-height: auto;
        }
      }

      &:nth-of-type(3) .product__text,
      &:nth-of-type(4) .product__text {
        min-height: 4.1426vw;
        @include mobile {
          min-height: auto;
        }
      }
    }
  }

  .product {
    &__title {
      font-size: 1.875vw;
      font-weight: bold;
      text-align: center;

      @include mobile {
        font-size: 1.8rem;
      }

      mark {
        display: block;
      }
    }

    &__text {
      margin-top: 0.5208vw;

      @include mobile {
        margin-top: 5px;
      }
    }

    &__img {
      margin-top: 0.7813vw;
      @include mobile {
        margin-top: 8px;
      }
    }
  }

  .step {
    &__item {
      + .step__item {
        margin-top: 3.125vw;
        position: relative;

        @include mobile {
          margin-top: 30px;
        }

        &::before {
          content: '';
          position: absolute;
          top: -1.5625vw;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2.0833vw;
          height: 1.8229vw;
          background-color: $red;
          clip-path: polygon(50% 100%, 0 0, 100% 0);

          @include mobile {
            top: -15px;
            width: 20px;
            height: 17px;
          }
        }
      }
    }

    &__title {
      font-size: 1.875vw;
      font-weight: bold;
      text-align: center;

      @include mobile {
        font-size: 1.8rem;
      }

      mark {
        display: block;
        &.hide-small {
          @include mobile {
            display: none;
          }
        }
        &.show-small {
          display: none;
          @include mobile {
            display: block;
          }
        }
      }
    }

    &__text {
      margin-top: 0.4em;
    }
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.0938vw;

    @include mobile {
      grid-template-columns: 1fr;
      row-gap: 7px;
    }
  }

  .sentence {
    &__title {
      font-size: 1.875vw;
      font-weight: bold;
      text-align: center;

      @include mobile {
        font-size: 1.8rem;
      }
    }

    &__text {
      margin-top: 0.4em;
    }

    + .sentence {
      margin-top: 1.5625vw;

      @include mobile {
        margin-top: 15px;
      }
    }
  }

  .link-list {
    &__item {
      + .link-list__item {
        margin-top: 2.448vw;

        @include mobile {
          margin-top: 23px;
        }
      }
    }
  }

  .pagination {
    margin-top: 5.9375vw;

    @include mobile {
      margin-top: 47px;
    }
  }
}
