.section-404 {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 10.1563vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 94px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 23px;
    }

    &__title {
      font-size: 1.875vw;
      text-align: center;
      font-weight: bold;
      margin-bottom: 0.5208vw;

      @include mobile {
        font-size: 1.8rem;
        margin-bottom: 5px;
      }
    }

    &__sub-title {
      font-size: 1.6667vw;
      text-align: center;
      font-weight: bold;

      @include mobile {
        font-size: 1.6rem;
      }
    }
  }

  .notfound {
    &__text {
      max-width: max-content;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 3.3854vw;

      @include mobile {
        margin-bottom: 23px;
      }
    }
  }
}
