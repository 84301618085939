.section-message {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 76px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .content {
    .article {
      &:nth-of-type(1) .article__name {
        margin-top: 0.5208vw;

        @include mobile {
          margin-top: 5px;
        }

        + .article__imgs {
          margin-top: 1.04167vw;

          @include mobile {
            margin-top: 16px;
          }
        }
      }

      &:nth-of-type(2) .detail {
        margin-top: 3.125vw;

        @include mobile {
          margin-top: 10px;
        }
      }

      + .article {
        margin-top: 3.125vw;

        @include mobile {
          margin-top: 20px;
        }
      }
    }
  }

  .article {
    &__name {
      font-weight: bold;
    }

    &__imgs {
      display: flex;

      > * {
        width: 50%;
      }
    }
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5208vw;

    @include mobile {
      margin-top: 5px;
    }

    .blog-link {
      margin-left: 1em;
      flex-shrink: 0;
    }
  }

  .blog-link {
    font-weight: bold;
    font-size: 1.6667vw;
    letter-spacing: 0.15em;
    padding: 0.14em 1em;
    text-align: center;
    width: 9.3229vw;

    @include mobile {
      font-size: 1.6rem;
      width: 94px;
    }
  }

  .detail {
    padding: 2.3958vw;
    border: 2px solid $orange;

    @include mobile {
      padding: 23px;
      border-width: 1px;
    }
  }

  .detail-defi {
    &__term {
      font-weight: bold;
      color: $orange;
    }

    &__desc {
      text-align: justify;
    }
  }
}
