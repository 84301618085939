.section-tester {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .subject {
    &__head {
      color: $red;
      font-weight: bold;
    }
  }

  .bg-text {
    margin-top: 3.125vw;

    @include mobile {
      margin-top: 20px;
    }
  }

  .pagination {
    margin-top: 5.9375vw;

    @include mobile {
      margin-top: 47px;
    }
  }
}
