.section-salon {
  .section-content {
    padding-top: 10.1563vw;

    @include mobile {
      padding-top: 94px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .content {
    .article {
      + .article {
        margin-top: 3.125vw;

        @include mobile {
          margin-top: 20px;
        }
      }

      &:nth-of-type(2) {
        .article__body {
          .box {
            margin-top: 3.125vw;
            margin-bottom: 3.125vw;

            @include mobile {
              margin-top: 23px;
              margin-bottom: 23px;
            }
          }
        }
      }
    }
  }

  .bridal-menu-list {
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__term {
      font-size: 1.875vw;
      font-weight: bold;
      line-height: 1.3333;

      @include mobile {
        font-size: 1.8rem;
      }
    }

    &__dots {
      width: 7.5521vw;
      text-align: center;
      min-width: max-content;

      @include mobile {
        width: 73px;
      }
    }

    &__price {
      width: 9.1667vw;
      min-width: max-content;
      text-align: right;

      @include mobile {
        width: 88px;
      }
    }
  }

  .menu-caution-list {
    margin: 1.2vw auto 0;
    max-width: max-content;

    @include mobile {
      margin: 10px 0 0;
    }

    &__item {
      text-indent: -1em;
      padding-left: 1em;
    }
  }

  .shop-details {
    &__item {
      display: flex;
    }

    &__term {
      display: flex;
      justify-content: space-between;
      width: 5.2083vw;
      min-width: max-content;
      font-weight: bold;
      flex-shrink: 0;

      @include mobile {
        width: 50px;
      }
    }

    &__desc {
      margin-left: 0.6667em;
    }
  }

  .map-iframe {
    position: relative;
    padding-top: 52.286%;

    margin-top: 0.7813vw;

    @include mobile {
      margin-top: 5px;
    }

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
