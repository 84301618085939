.section-support {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .more-link {
    margin-top: 5.9375vw;
    width: 32.5521vw;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
      margin-top: 47px;
      width: auto;
    }

    &__text {
      font-size: 1.5625vw;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.3em;

      @include mobile {
        font-size: 1.5rem;
      }
    }

    &__link {
      display: block;
    }
  }

  .pagination {
    margin-top: 5.9375vw;

    @include mobile {
      margin-top: 47px;
    }
  }
}
