.section-company {
  .section-content {
    padding-top: 10.1563vw;

    @include mobile {
      padding-top: 94px;
    }
  }

  .headline {
    margin-bottom: 5.7292vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .shop-details {
    &__item {
      display: flex;
    }

    &__term {
      display: flex;
      justify-content: space-between;
      width: 7.8125vw;
      min-width: max-content;
      font-weight: bold;

      @include mobile {
        width: 75px;
      }
    }

    &__desc {
      margin-left: 0.6667em;
    }
  }

  .map-iframe {
    position: relative;
    padding-top: 52.286%;

    margin-top: 0.7813vw;

    @include mobile {
      margin-top: 5px;
    }

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
