.section-contact {
  .headline {
    &__title {
      font-size: 1.875vw;
      text-align: center;
      font-weight: bold;

      @include mobile {
        font-size: 1.8rem;
      }
    }
  }

  .form-group {
    .form-group__item {
      &:first-of-type {
        grid-column-start: 1;
        grid-column-end: 2;

        @include mobile {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }

    &__label {
      margin-bottom: 0.25em;

      @include mobile {
        margin-bottom: 0.25em;
      }
    }
  }

  .form-group__item {
    &:first-of-type .form-group__label {
      justify-content: left;
    }
  }

  .form-group__control p {
    padding: 0em 0em;
    font-size: 1.2em;
    min-height: 1.2em;
  }

  .submit-button {
    display: block;
    margin-top: 1.7708vw;
    width: 33.3333vw;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
      margin-top: 15px;
      width: 100%;
    }
  }

  .button-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.2812vw;

    @include mobile {
      display: block;
    }
  }
}
