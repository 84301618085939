.section-concept {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .content {
    .article {
      &:first-of-type {
        margin-bottom: 6.25vw;

        @include mobile {
          margin-bottom: auto;
        }
      }

      &:nth-last-of-type(1) .article__title,
      &:nth-last-of-type(2) .article__title,
      &:nth-last-of-type(3) .article__title {
        @include mobile {
          font-size: 1.4rem;
        }
      }

      + .article {
        margin-top: 3.125vw;

        @include mobile {
          margin-top: 20px;
        }
      }
    }

    .pagination {
      margin-top: 5.9375vw;

      @include mobile {
        margin-top: 47px;
      }
    }
  }
}
