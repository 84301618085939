.section-about {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .content {
    .article {
      + .article {
        margin-top: 3.125vw;

        @include mobile {
          margin-top: 20px;
        }
      }

      &:nth-of-type(4) {
        margin-top: -10px;
      }

      &:nth-of-type(5) {
        .article {
          &__text {
            &:nth-of-type(2) {
              margin-top: 0.7813vw;

              @include mobile {
                margin-top: 5px;
              }
            }
          }
        }
      }

      &:nth-of-type(7) .article__title,
      &:nth-of-type(8) .article__title {
        @include mobile {
          font-size: 1.6rem;
        }
      }
    }

    .pagination {
      margin-top: 5.9375vw;

      @include mobile {
        margin-top: 47px;
      }
    }
  }
}
