.section-faq {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .faq {
    &__head {
      margin-bottom: 3.3854vw;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    + .faq {
      margin-top: 2.6042vw;

      @include mobile {
        margin-top: 20px;
      }
    }
  }

  .faq-list {
    &__item {
      + .faq-list__item {
        margin-top: 3.3854vw;
        position: relative;

        @include mobile {
          margin-top: 20px;
        }

        &::before {
          content: '';
          position: absolute;
          top: -1.8229vw;
          left: 0;
          width: 100%;
          height: 0.4167vw;
          background-color: $red;

          @include mobile {
            top: -12px;
            height: 4px;
          }
        }
      }
    }

    &__term {
      position: relative;
      padding-left: 2.4479vw;
      font-size: 1.4583vw;
      font-weight: bold;
      margin-bottom: 1.0417vw;

      @include mobile {
        font-size: 1.4rem;
        padding-left: 22px;
        margin-bottom: 5px;
      }
    }

    &__desc {
      position: relative;
      padding-left: 2.4479vw;

      @include mobile {
        padding-left: 22px;
      }

      a {
        color: $navy;
        font-weight: bold;
      }
    }

    &__term::before,
    &__desc::before {
      position: absolute;
      left: 0;
      font-family: $font__din;
      font-size: 1.5625vw;
      line-height: 0.9;

      @include mobile {
        font-size: 1.5rem;
      }
    }

    &__term::before {
      content: 'Q.';
      top: calc(1.5625vw * 1.25);
      transform: translate(0, -100%);

      @include mobile {
        top: calc(1.4rem * 1.25);
      }
    }

    &__desc::before {
      content: 'A.';
      top: calc(max(1.25vw, 1.2rem) * 1.25);
      transform: translate(0, -100%);

      @include mobile {
        top: calc(1.2rem * 1.25);
      }
    }
  }

  .pagination {
    margin-top: 5.9375vw;

    @include mobile {
      margin-top: 47px;
    }
  }
}
