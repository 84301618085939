.section-privacy {
  .section-content {
    padding-top: 4.8438vw;
    padding-bottom: 4.8438vw;

    @include mobile {
      padding-top: 47px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 2.0833vw;

    @include mobile {
      margin-bottom: 5px;
    }

    &__title {
      font-size: 1.875vw;
      text-align: center;
      font-weight: bold;

      @include mobile {
        font-size: 1.8rem;
      }
    }
  }

  .privacy-list {
    &__title {
      font-size: inherit;
    }

    &__item {
      + .privacy-list__item {
        margin-top: 1.5625vw;

        @include mobile {
          margin-top: 8px;
        }
      }
    }

    &__title {
      font-weight: bold;
    }

    &__text {
      text-align: justify;
    }
  }
}
