.section-salon {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 6.5104vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .salon-list {
    &__item {
      + .salon-list__item {
        margin-top: 3.3854vw;

        @include mobile {
          margin-top: 20px;
        }
      }
    }
  }

  .salon {
    display: flex;

    @include mobile {
      display: block;
    }

    > * {
      width: 50%;

      @include mobile {
        width: auto;
      }
    }

    &__body {
      margin-bottom: 5px;
      padding-right: 4%;
    }

    &__name {
      font-size: 1.875vw;
      font-weight: bold;
      line-height: 1.3333;
      margin-bottom: 0.3333em;

      @include mobile {
        font-size: 1.8rem;
        margin-bottom: 0.25em;
      }
    }

    &__link {
      word-break: break-all;
    }
  }

  .salon-defi {
    display: flex;

    &__term {
      display: flex;
      justify-content: space-between;
      width: 4.1667vw;
      min-width: max-content;
      font-weight: bold;

      @include mobile {
        width: 39px;
      }
    }

    &__desc {
      margin-left: 0.6667em;
    }

    &__img {
      img {
        width: 100%;
      }
    }
  }

  .pagination {
    margin-top: 5.9375vw;

    @include mobile {
      margin-top: 47px;
    }
  }
}
