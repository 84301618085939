.section-experience {
  .section-content {
    padding-top: 10.1563vw;

    @include mobile {
      padding-top: 94px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .experience {
    + .experience {
      margin-top: 2.8646vw;
      position: relative;

      @include mobile {
        margin-top: 30px;
      }

      &::before {
        content: '';
        position: absolute;
        top: -1.5625vw;
        left: 0;
        height: 0.4167vw;
        width: 100%;
        background-color: $red;

        @include mobile {
          top: -18px;
          height: 4px;
        }
      }
    }

    &__param {
      display: flex;
      justify-content: center;
    }

    &__param-item {
      + .experience__param-item {
        &::before {
          content: '｜';
        }
      }
    }

    &__evaluation {
      text-align: center;
      color: $red;
      font-size: 2.0833vw;
      letter-spacing: 0.15em;
      line-height: 1.1;

      @include mobile {
        font-size: 2rem;
      }
    }
  }
}
