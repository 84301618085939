.section-contact {
  .headline {
    &__title {
      font-size: 1.875vw;
      text-align: center;
      font-weight: bold;

      @include mobile {
        font-size: 1.8rem;
      }
    }
  }

  .link-area {
    margin-top: 3.3854vw;

    @include mobile {
      margin-top: 23px;
    }
  }
}
