.section-news {
  .section-content {
    padding: 7.2917vw 0;

    @include mobile {
      padding: 45px 0;
    }
  }

  .news {
    + .news {
      margin-top: 7.2917vw;

      @include mobile {
        margin-top: 40px;
      }
    }

    &__head {
      margin-bottom: 3.6458vw;
      line-height: 1.2;

      @include mobile {
        margin-bottom: 15px;
      }
    }

    &__time {
      display: block;
      font-size: 0.9375vw;
      margin-bottom: 1.0417vw;

      @include mobile {
        font-size: 0.9rem;
        margin-bottom: 10px;
      }
    }

    &__title {
      font-size: 1.6667vw;
      font-weight: bold;

      @include mobile {
        font-size: 1.6rem;
      }
    }

    &__img {
      margin-bottom: 3.6458vw;

      @include mobile {
        margin-bottom: 15px;
      }

      img {
        width: 100%;
      }
    }

    &__content {
      word-break: break-all;

      * {
        font-weight: revert;
        margin: revert;
        padding: revert;
      }

      ul,
      ol {
        list-style-type: revert;
      }

      a {
        color: $navy;
        font-weight: bold;
      }
    }
  }

  .nav-links {
    margin-top: 7.2917vw;
    font-size: 1.2em;
    text-align: center;

    @include mobile {
      margin-top: 40px;
    }

    > * {
      display: inline-block;
      padding: 0.5em;
      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    .page-numbers {
      &.current {
        color: $blue;
      }
    }
  }
}
