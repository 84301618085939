.section-site-map {
  .section-content {
    padding-top: 4.8438vw;
    padding-bottom: 7.4479vw;
    padding-right: 25px;
    padding-left: 25px;
    max-width: 1360px;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
      padding-top: 47px;
      padding-bottom: 58px;
    }
  }

  .site-map {
    overflow: hidden;
    margin-bottom: -2.3958vw;

    @include mobile {
      margin-bottom: -23px;
    }

    &__row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-right: -2.3958vw;

      @include mobile {
        margin-right: -23px;
      }

      > * {
        width: calc(25% - 2.3958vw);
        margin-right: 2.3958vw;
        margin-bottom: 2.3958vw;

        @include mobile {
          width: calc(50% - 23px);
          margin-right: 23px;
          margin-bottom: 23px;
        }
      }

      &_column3 {
        > *:nth-child(3n - 2) {
          margin-left: calc(12.5%);

          @include mobile {
            margin-left: 0;
          }
        }

        > *:nth-child(3n) {
          margin-right: calc(12.5% + 2.3958vw);

          @include mobile {
            margin-right: 23px;
          }
        }
      }
    }

    &__link {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.23em 0.5em;
      border: 1px solid $base-font-color;
      transition: 0.3s;

      @media (hover: hover) {
        &:hover {
          opacity: 1;
          background-color: $base-font-color;
          color: #fff;
        }
      }
    }
  }
}
