.section-beauty {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 23px;
    }
  }

  .content {
    .intro {
      margin-bottom: 3.3854vw;
      background-color: $ice-blue;
      border-radius: 2.0834vw;
      font-size: 1.3541vw;
      font-weight: bold;
      padding: 1.5em;

      @include mobile {
        margin-bottom: 20px;
        font-size: 1.3rem;
        border-radius: 20px;
      }
    }

    .article {
      + .article {
        margin-top: 3.125vw;

        @include mobile {
          margin-top: 20px;
        }
      }
    }

    .method {
      margin-top: 6.9792vw;

      @include mobile {
        margin-top: 23px;
      }

      &__title {
        font-size: 1.875vw;
        font-weight: bold;
        text-align: center;
        padding: 0.5em 1em;
        background-color: $ice-blue;

        @include mobile {
          font-size: 1.8rem;
          padding: 0.45em 1em;
        }
      }

      .method-list {
        margin-top: 3.3854vw;

        @include mobile {
          margin-top: 0;
        }
      }
    }

    .method-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-top: 2px solid $ice-blue;
      border-left: 2px solid $ice-blue;

      @include mobile {
        display: block;
        border: {
          top: none;
          left: none;
        }
      }

      &__item {
        border: {
          bottom: 2px solid $ice-blue;
          right: 2px solid $ice-blue;
        }
        padding: 2.3958vw;

        @include mobile {
          border: {
            right: 1px solid $ice-blue;
            bottom: 0.5px solid $ice-blue;
            left: 1px solid $ice-blue;
          }
          padding: 23px;
        }
      }

      &__img-wrap {
        display: flex;
      }

      &__num {
        background-color: $ice-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16.6667%;
        font-family: $font__din;
        font-weight: bold;
        font-size: 3.125vw;

        @include mobile {
          font-size: 3rem;
        }
      }

      &__img {
        width: 83.3333%;
      }

      &__text {
        margin-top: 0.2em;
        margin-bottom: -0.2em;

        @include mobile {
          margin-top: 5px;
        }
      }
    }

    .bg-text {
      margin-top: 6.9792vw;

      @include mobile {
        margin-top: 47px;
      }
    }

    .pagination {
      margin-top: 5.9375vw;

      @include mobile {
        margin-top: 47px;
      }
    }
  }

  .movie {
    margin-top: 6.9792vw;
    padding: 0 5%;

    @include mobile {
      margin-top: 47px;
      padding: 0 8px;
    }
  }
}
