.section-contact {
  .section-content {
    padding-top: 10.1563vw;
    padding-bottom: 5.9375vw;

    @include mobile {
      padding-top: 94px;
      padding-bottom: 47px;
    }
  }

  .headline {
    margin-bottom: 3.3854vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .intro {
    margin-bottom: 2.9167vw;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .contact-form {
    .error {
      color: $red;
      font-weight: bold;
      margin-top: 0.25em;
    }
  }

  .form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.2812vw;

    @include mobile {
      gap: 20px;
    }

    .form-group__item {
      @include mobile {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      &:first-of-type,
      &:last-of-type,
      &:nth-last-of-type(2) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    &__label {
      margin-bottom: 0.5em;
      display: flex;
      justify-content: space-between;
      align-items: end;
      font-weight: bold;

      @include mobile {
        margin-bottom: 0.75em;
      }

      .caution {
        font-weight: normal;
        font-size: 0.75em;
      }
    }

    .form-group__item {
      &:first-of-type .form-group__label {
        justify-content: center;
      }
    }

    &__zip {
      display: flex;
      align-items: center;
      width: calc((100% - 3.2812vw) / 2);

      @include mobile {
        width: 100%;
      }

      label {
        margin-right: 0.5em;
        display: inline-flex;
        flex-shrink: 0;
      }

      input[name='zip'] {
        min-width: 3.5em;
        flex-grow: 1;
      }

      .button {
        margin-left: 2.4479vw;
        min-width: 8.9583vw;
        flex-shrink: 0;

        @include mobile {
          min-width: 94px;
          margin-left: 23px;
        }
      }
    }

    input[name='address'] {
      margin-top: 0.6666em;
    }
  }

  .radio-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: -1.5625vw;
    margin-bottom: -0.7813vw;

    @include mobile {
      margin-bottom: -7px;
    }

    &__item {
      margin-right: 1.5625vw;
      margin-bottom: 0.7813vw;
      width: calc((100% - 1.5625vw * 2) / 2);

      @include mobile {
        margin-bottom: 7px;
        width: 100%;
      }

      label {
        display: inline-block;
        width: 100%;
      }
    }
  }

  input[type='radio'] + label,
  .button {
    padding: 0.23em 1em;
    border: 1px solid $base-font-color;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;

    @media (hover: hover) {
      &:hover {
        background-color: $base-font-color;
        color: #fff;
        opacity: 1;
      }
    }

    &_invert {
      background-color: $base-font-color;
      color: #fff;

      @media (hover: hover) {
        &:hover {
          background-color: #fff;
          color: $base-font-color;
        }
      }
    }
  }

  input[type='radio']:checked + label {
    background-color: $base-font-color;
    color: #fff;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    background-color: $light-gray;
    padding: 0.23em 0.8em;
    width: 100%;

    &:focus {
      outline: 1px solid $base-font-color;
    }
  }

  textarea[name='content'] {
    height: 41.1979vw;

    @include mobile {
      height: 395px;
    }
  }

  .outro {
    margin-top: 3.2812vw;

    @include mobile {
      margin-top: 20px;
    }
  }

  .submit-button {
    display: block;
    margin-top: 1.7708vw;
    width: 33.3333vw;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
      margin-top: 15px;
      width: 100%;
    }
  }
}
